<template>
  <div
    class="pt-12 grey lighten-3"
    style="height:calc(100% - 50px);overflow:auto"
    ref="card"
  >
    <v-card class="ma-4 px-4 py-4">
      <v-sheet
        color="primary"
        width="100%"
        elevation="6"
        class="px-4 py-2 v-card-material mb-n6 rounded"
        dark
      >
        <div class="v-card-material-title pt-1">
          腹膜透析患者自我管理能力
        </div>
        <div class="v-card-material-subtitle py-1">
          腹透远程系统
        </div>
      </v-sheet>
      <v-form v-model="valid" ref="form">
        <template :name="keys[0]">
          <v-chip
            class="elevation-2 mb-4"
            color="primary"
            label
            text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ keys[0] }}
          </v-chip>
          <RadioGroup
            v-for="(sub, index) in questions[keys[0]]"
            :key="index + 1"
            :title="sub.question"
            :selection="sub.selection"
            :callback="(val) => (baseInfo[`ITEM${index + 1}`] = val)"
            :ref="`ITEM${index + 1}`"
            :rules="[
              (v) => !!v || rule(`ITEM${index + 1}`) || '选项不能为空！',
            ]"
          />
        </template>
        <template :name="keys[1]">
          <v-chip
            class="elevation-2 mb-4"
            color="primary"
            label
            text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ keys[1] }}
          </v-chip>
          <RadioGroup
            v-for="(sub, index) in questions[keys[1]]"
            :key="index + 11"
            :title="sub.question"
            :selection="sub.selection"
            :callback="(val) => (baseInfo[`ITEM${index + 11}`] = val)"
            :ref="`ITEM${index + 11}`"
            :rules="[
              (v) => !!v || rule(`ITEM${index + 11}`) || '选项不能为空！',
            ]"
          />
        </template>
        <template :name="keys[2]">
          <v-chip
            class="elevation-2 mb-4"
            color="primary"
            label
            text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ keys[2] }}
          </v-chip>
          <RadioGroup
            v-for="(sub, index) in questions[keys[2]]"
            :key="index + 22"
            :title="sub.question"
            :selection="sub.selection"
            :callback="(val) => (baseInfo[`ITEM${index + 22}`] = val)"
            :ref="`ITEM${index + 22}`"
            :rules="[
              (v) => !!v || rule(`ITEM${index + 22}`) || '选项不能为空！',
            ]"
          />
        </template>
        <template :name="keys[3]">
          <v-chip
            class="elevation-2 mb-4"
            color="primary"
            label
            text-color="white"
          >
            <v-icon left>
              mdi-label
            </v-icon>
            {{ keys[3] }}
          </v-chip>
          <RadioGroup
            v-for="(sub, index) in questions[keys[3]]"
            :key="index + 29"
            :title="sub.question"
            :selection="sub.selection"
            :callback="(val) => (baseInfo[`ITEM${index + 29}`] = val)"
            :ref="`ITEM${index + 29}`"
            :rules="[
              (v) => !!v || rule(`ITEM${index + 29}`) || '选项不能为空！',
            ]"
          />
        </template>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="commit" :loading="loading">
          提交
        </v-btn>
      </v-card-actions>
    </v-card>
    <PreCheck />
  </div>
</template>
<script>
import { mapState } from "vuex";
import PreCheck from "@/components/Local/PreCheck.vue";
import RadioGroup from "@/components/Local/RadioGroup.vue";
export default {
  name: "SelfManagement",
  components: { RadioGroup, PreCheck },
  data: () => ({
    loading: false,
    questions: global.questions["selfManagement"],
    keys: Object.keys(global.questions["selfManagement"]),
    baseInfo: {},
    valid: false,
    rulePostion: true,
  }),
  computed: {
    ...mapState(["openId", "currentUser"]),
  },
  methods: {
    rule(target) {
      if (this.rulePostion && this.$refs[target]) {
        let temp = this.$refs[target];
        if (this.$refs[target].constructor == Array) {
          temp = this.$refs[target][0];
        }
        this.$vuetify.goTo(temp, {
          duration: 300,
          offset: 0,
          easing: "easeInOutCubic",
          container: this.$refs.card,
        });
        this.rulePostion = false;
      }
      return false;
    },
    commit() {
      console.log(this.baseInfo);
      if (!this.valid) {
        this.$refs.form.validate();
        this.rulePostion = true;
      } else {
        this.save();
      }
    },
    save() {
      this.loading = true;
      this.$api
        .postAdd_SNepgauge({
          CD_NAME: "自我管理能力",
          IDCARD: this.currentUser.IDCARD,
          ...this.baseInfo,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.$notify.success({
              message: data.message,
            });
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.v-card-material {
  position: relative;
  top: -40px;
  transition: 0.3s ease;
  z-index: 1;
}
.v-card-material-title {
  font-size: 1.125rem;
}
.v-card-material-subtitle {
  font-size: 0.875rem;
  color: #ffffffaa;
}
.m_black {
  font-size: 16px;
  color: #000000a0;
}
.bg_4 {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
</style>
