<template>
  <v-dialog
    persistent
    :value="!openId || openId === 'no_open_id' || !currentUser"
  >
    <v-card color="red" class="pa-4 text-center" dark>
      {{ text }}
      <v-card-actions
        class="pa-0 pt-4"
        v-if="openId && openId !== 'no_open_id'"
      >
        <v-spacer />
        <v-btn small outlined class="white--text" @click="commit"
          >前往录入</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PreCheck",
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(["openId", "currentUser"]),
    text() {
      if (!this.openId || this.openId === "no_open_id") {
        return "请在公众号中打开";
      } else if (!this.currentUser) {
        return "用户未录入，请先维护个人信息";
      } else {
        return "";
      }
    },
  },
  methods: {
    commit() {
      this.$router.replace("/UserInfo");
    },
  },
};
</script>
<style scoped></style>
