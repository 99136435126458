<template>
  <v-radio-group v-model="result" class="mt-0" :rules="rules">
    <template v-slot:label>
      <div class="m_black">{{ title }}</div>
    </template>
    <v-radio :label="item" :value="item" v-for="item in list" :key="item" />
  </v-radio-group>
</template>
<script>
export default {
  name: "RadioGroup",
  props: {
    title: {
      type: String,
      default: "选项",
    },
    rules: {
      type: Array,
      default: function() {
        return [(v) => !!v || `选项不能为空！`];
      },
    },
    selection: {
      type: String,
      default: "frequency_1",
    },
    callback: Function,
  },
  data: () => ({
    result: undefined,
    list: [],
  }),
  watch: {
    result(val) {
      val && this.callback && this.callback(val);
    },
  },
  created() {
    this.list = global.selection[this.selection] || [];
  },
  methods: {},
};
</script>
<style scoped></style>
